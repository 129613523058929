import React from "react";
import { VOLUME_MUTE, VOLUME_UP } from "../../assets/Images";

const Speaker = ({ handleMic, isMicOn, appearance }) => {
  const isScreenMdOrLarger = window.innerWidth >= 768;

  return (
    <div
      style={
        isScreenMdOrLarger
          ? { background: appearance?.buttonBackgroundColor || "black" }
          : {}
      }
      className={`flex justify-center items-center w-[45px] h-[45px] rounded-[25px] p-[10px] md:shadow-xl cursor-pointer`}
      onClick={handleMic}
    >
      {isMicOn ? (
        <VOLUME_UP
          style={{ fill: appearance?.iconColor || "white" }}
          className="w-full h-full"
        />
      ) : (
        <VOLUME_MUTE
          style={{ fill: appearance?.iconColor || "white" }}
          className="w-full h-full"
        />
      )}
    </div>
  );
};

export default Speaker;
