import React from "react";
import Speaker from "../Buttons/Speaker";
import SignOut from "../Buttons/SignOut";

const Header = ({ isMicOn, handleMic, appearance }) => {
  return (
    <header
      style={{ background: appearance?.headerBackgroundColor || "#c4d8e4" }}
      className="flex flex-col w-full p-4 shadow-2xl lg:flex-row"
    >
      <div className="flex flex-grow justify-between h-[20px] md:h-[65px] lg:h-[80px]  md:justify-around items-center p-0 lg:p:4">
        <SignOut appearance={appearance} />
        <div>
          <h1
            style={{ color: appearance?.buttonBackgroundColor || "black" }}
            className="text-[26px] font-poppins font-[700]"
          >
            Lara
          </h1>
        </div>
        <Speaker
          isMicOn={isMicOn}
          handleMic={handleMic}
          appearance={appearance}
        />
      </div>
    </header>
  );
};

export default Header;
