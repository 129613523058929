import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

async function getUserAppearance() {
  const userId = localStorage.getItem("uid");

  if (!userId) {
    console.warn("User ID not found in local storage.");
    return null;
  }

  try {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);
    if (!userSnapshot.exists()) {
      console.error("User document not found!");
      return null;
    }

    const appearanceId = userSnapshot.data()?.appearance;
    if (!appearanceId || !appearanceId.length) {
      return null;
    }
    const appearanceRef = doc(db, "appearances", appearanceId);
    const appearanceSnapshot = await getDoc(appearanceRef);
    if (!appearanceSnapshot.exists()) {
      return null;
    }

    return appearanceSnapshot.data();
  } catch (error) {
    console.error("Error getting appearance data: ", error);
    return null;
  }
}

export { getUserAppearance };
