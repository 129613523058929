import React, { useState, useEffect, useRef } from "react";
import { getChatAnswer, fetchSpeech } from "../../api/chat";
import { getChatConfigs, getSpeechConfigs } from "../../config/database/chat";
import { SEND_ICON, SCROLL_TO_BOTTOM, MICROPHONE } from "../../assets/Images";
import "./chat.css";
import Header from "../../components/Header";
import ChatMessage from "../../components/ChatMessage";
import micGif from "../../assets/Images/mic.gif";
import { useSelector } from "react-redux";
import { getChat } from "../../store/apps/chat";
import { getUserAppearance } from "../../config/database/appearance";
import LoadingScreen from "../../components/Spinner/LoadingScreen";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Chat = () => {
  const chat = useSelector(getChat);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isMicOn, setIsMicOn] = useState(true);
  const [chatConfigData, setChatConfigData] = useState();
  const [divHeight, setDivHeight] = useState(0);
  const [speechConfigData, setSpeechConfigData] = useState({});
  const [speakingStates, setSpeakingStates] = useState({});
  const [isLongPress, setIsLongPress] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [isReSpeaking, setIsReSpeaking] = useState({});
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const [appearance, setAppearance] = useState(null);
  const [loading, setLoading] = useState(true);
  const backgroundStyle =
    appearance &&
    appearance.assistantChatBackgroundColor &&
    appearance.userChatBackgroundColor
      ? {
          background: `linear-gradient(to right, ${appearance.assistantChatBackgroundColor}, ${appearance.userChatBackgroundColor})`,
        }
      : {
          background: "linear-gradient(to right, #26A69A, #2196F3",
        };

  const divRef = useRef(null);
  const messageContainerRef = useRef(null);
  const recognitionRef = useRef();

  useEffect(() => {
    const fetchSpeechConfigData = async () => {
      const configData = await getSpeechConfigs();
      setSpeechConfigData(configData);
    };

    fetchSpeechConfigData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchAppearanceData = async () => {
      const appearanceData = await getUserAppearance();
      setAppearance(appearanceData);
      setLoading(false);
    };
    fetchAppearanceData();
  }, []);

  useEffect(() => {
    if (chat.length > 0 && Object.keys(speechConfigData).length > 0) {
      setMessages([...messages, chat[1]]);
      speakMessage(chat[1], 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, speechConfigData]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      messageContainerRef.current;
    setShowScrollButton(scrollHeight - scrollTop > clientHeight);
  };

  useEffect(() => {
    const fetchChatConfig = async () => {
      const data = await getChatConfigs();
      setChatConfigData(data);
    };

    fetchChatConfig();
  }, []);

  useEffect(() => {
    if (messageContainerRef.current) {
      scrollToBottom();
    }
  }, [messages]);

  const scrollToBottom = () => {
    messageContainerRef.current.scrollTop =
      messageContainerRef.current.scrollHeight;
  };

  const sendMessage = async () => {
    if (!inputValue.trim()) return;

    if (
      messages.length > 1 &&
      messages[messages.length - 1].content === "Typing ..."
    ) {
      return;
    }

    const userMessage = {
      role: "user",
      content: inputValue.trim(),
    };
    // Include user message
    const newMessages = [...messages, userMessage];
    // typing indicator
    const typingMessage = {
      role: "assistant",
      content: "Typing ...",
      typing: true,
    };

    setMessages([...newMessages, typingMessage]);
    setInputValue("");

    const relevantMessages = newMessages.slice(-7);

    try {
      const response = await getChatAnswer({
        messages: relevantMessages,
        ...chatConfigData,
      });
      const assistantReply = {
        role: "assistant",
        content: response.choices[0].message.content,
      };
      const updatedMessages = [...newMessages, assistantReply];
      setMessages(updatedMessages);
      speakMessage(assistantReply, updatedMessages.length - 1);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      const updatedMessagesWithError = [...newMessages, errorMessage];
      setMessages(updatedMessagesWithError);
    }
  };

  const speakMessage = async (message, index) => {
    if (isMicOn) {
      setSpeakingStates((prev) => ({ ...prev, [index]: true }));
      await fetchSpeech({ text: message.content, ...speechConfigData });
      setSpeakingStates((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleMic = () => {
    setIsMicOn(!isMicOn);
  };

  useEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setDivHeight(divRef.current.offsetHeight);
      }
    };

    !loading && updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [loading]);

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition not supported in this browser.");

      return;
    }
    const recognitionInstance = new window.webkitSpeechRecognition();
    recognitionInstance.continuous = true;
    recognitionInstance.interimResults = true;
    recognitionInstance.lang = "en-US";
    recognitionInstance.onresult = (event) => {
      let interim = "";
      let final = "";
      for (let i = 0; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          final += transcript;
        } else {
          interim += transcript;
        }
      }
      setInputValue(final + interim);
    };

    recognitionInstance.onerror = (event) => {
      console.error("Speech recognition error", event);
    };
    setRecognition(recognitionInstance);
  }, []);

  useEffect(() => {
    const checkIfTouchDevice = () => {
      setIsTouchDevice(
        "ontouchstart" in window || navigator.maxTouchPoints > 0
      );
    };

    checkIfTouchDevice();
  }, []);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsLongPress(true);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);
    if (recognition) {
      recognition.start();
    }
  };

  const handleMouseUp = () => {
    setIsLongPress(false);
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("mousemove", handleMouseMove);
    if (recognition) {
      recognition.stop();
    }
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 0) {
      handleMouseUp();
    }
  };

  const handleTouchStart = (e) => {
    if (isLongPress) {
      recognitionRef.current?.stop();
      setIsLongPress(false);
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = "en-US";
    recognitionRef.current.onstart = function () {
      setIsLongPress(true);
    };
    recognitionRef.current.onend = function () {
      setIsLongPress(false);
    };
    recognitionRef.current.onresult = async function (event) {
      let interim = "";
      let final = "";
      for (let i = 0; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          final += transcript;
        } else {
          interim += transcript;
        }
      }

      if (window.navigator.platform.toLowerCase().includes("linux arm")) {
        setInputValue(event.results[event.results.length - 1][0].transcript);
      } else {
        setInputValue(final + interim);
      }
    };

    recognitionRef.current.start();
  };

  const { height } = useWindowDimensions();

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="flex flex-col">
          <div ref={divRef}>
            <Header
              handleMic={handleMic}
              isMicOn={isMicOn}
              appearance={appearance}
            />
          </div>
          <div
            className="flex flex-col pb-2"
            style={{
              ...backgroundStyle,
              height: !isTouchDevice
                ? `calc(100vh - ${divHeight}px)`
                : height / 1.077,
            }}
          >
            <div className="flex flex-grow overflow-auto w-full p-[10px] justify-center items-center ">
              <div
                style={{ background: appearance?.backgroundColor || "#c4d8e4" }}
                className="relative flex flex-col h-full w-full md:w-[70%] shadow-xl rounded-2x"
              >
                <div
                  className="chat-area m-[20px] gap-8 overflow-scroll scroll-smooth"
                  ref={messageContainerRef}
                  onScroll={handleScroll}
                >
                  {messages.map((message, index) => (
                    <React.Fragment key={index}>
                      <ChatMessage
                        message={message}
                        speechConfigData={speechConfigData}
                        isSpeaking={speakingStates[index] || false}
                        index={index}
                        setSpeakingStates={setSpeakingStates}
                        isReSpeaking={isReSpeaking[index] || false}
                        setIsReSpeaking={setIsReSpeaking}
                        isMicOn={isMicOn}
                        appearance={appearance}
                      />
                    </React.Fragment>
                  ))}
                  {showScrollButton && (
                    <SCROLL_TO_BOTTOM
                      className="fill-black fixed bottom-[12%] right-[4%] md:right-[16%] w-[30px] h-[30px]"
                      style={{ backdropFilter: "blur(2px)" }}
                      onClick={scrollToBottom}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full justify-center items-center px-[10px]">
              <div
                style={{ background: appearance?.backgroundColor || "#c4d8e4" }}
                className="w-full md:w-[70%] md:rounded-[15px] h-[50px] shadow-lg flex items-center overflow-hidden border-[1px] border-black mt-0 mb-auto"
              >
                <style jsx>{`
                  input::placeholder {
                    color: ${appearance?.textColor || "#000"};
                  }
                `}</style>
                <input
                  value={inputValue}
                  style={{ color: appearance?.textColor || "#000" }}
                  className="bg-transparent font-poppins font-[500] text-[16px] w-full h-full py-2 pl-[12px] px-[5px] md:px-[29px] leading-tight focus:outline-none focus:shadow-outline "
                  type="text"
                  autoComplete="off"
                  placeholder="Please enter your question"
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
                {!isTouchDevice ? (
                  <div
                    className="flex items-center justify-center mr-[10px] cursor-pointer"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                  >
                    {isLongPress ? (
                      <img
                        src={micGif}
                        alt="mic"
                        width={30}
                        draggable="false"
                      />
                    ) : (
                      <MICROPHONE className="w-[30px]" />
                    )}
                  </div>
                ) : (
                  <button
                    type="button"
                    className="flex items-center justify-center mr-[10px] cursor-pointer"
                    onTouchStart={handleTouchStart}
                  >
                    {isLongPress ? (
                      <div
                        className="w-[30px] h-[30px]"
                        style={{
                          background: `url(${micGif}) no-repeat center`,
                          backgroundSize: "cover",
                        }}
                      />
                    ) : (
                      <MICROPHONE className="w-[30px]" />
                    )}
                  </button>
                )}
                <div
                  className="flex items-center justify-center p-[8px] pr-[12px]"
                  onClick={sendMessage}
                >
                  <SEND_ICON className="w-[25px] h-[25px] cursor-pointer " />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Chat;
