import React, { useState } from "react";
import "./styles.css";
import { fetchSpeech, stopAudio } from "../../api/chat";
import {
  ROBOT_ICON,
  USER_ICON,
  REPEAT,
  STOP_ICON,
  COPY,
} from "../../assets/Images";
import audioWaveGif from "../../assets/Images/audio-wave.gif";
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import Tooltip from "@mui/material/Tooltip";

function ChatMessage({
  message,
  speechConfigData,
  isSpeaking,
  index,
  setSpeakingStates,
  isReSpeaking,
  setIsReSpeaking,
  isMicOn,
  appearance,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleFetchSpeech = async (message) => {
    setIsReSpeaking({});
    setSpeakingStates({});
    if (isMicOn) {
      setIsReSpeaking((prev) => ({ ...prev, [index]: true }));
      await fetchSpeech({ text: message.content, ...speechConfigData });
      setIsReSpeaking((prev) => ({ ...prev, [index]: false }));
    }
  };
  const handleStopFetchingSpeech = () => {
    stopAudio();
    setIsReSpeaking({});
    setSpeakingStates({});
  };
  let messageString = message.content;
  let lines = messageString.split("\n");

  const md = new MarkdownIt({
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return (
            '<pre class="hljs"><code>' +
            hljs.highlight(lang, str, true).value +
            "</code></pre>"
          );
        } catch (__) {}
      }

      return (
        '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + "</code></pre>"
      );
    },
  });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message.content).then(() => {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 1000);
    });
  };

  return (
    <div className="mb-[20px]">
      {message.role === "assistant" ? (
        <div className="flex justify-start items-center">
          <div className="flex">
            <div
              className="flex shrink-0 justify-center items-center shadow-xl p-[5px] w-[30px] h-[30px] md:w-[42px] md:h-[42px] rounded-[25px] mr-[10px]"
              style={{
                borderWidth: "2px",
                borderColor: appearance?.userChatBackgroundColor || "#2196F3",
                borderStyle: "solid",
              }}
            >
              <ROBOT_ICON
                style={{
                  fill: appearance?.userChatBackgroundColor || "#2196F3",
                }}
                className={`flex w-full h-full`}
              />
            </div>
            <div
              style={{
                background:
                  message.type && message.type === "error"
                    ? "#e34e3d"
                    : appearance?.userChatBackgroundColor || "#2196F3",
              }}
              className={`flex flex-col px-[10px] py-[5px] text-[14px] font-poppins font-[400] md:text-[18px] md:leading-[20px] rounded-r-1x rounded-bl-1x shadow-assist mr-[12px] md:mr-[15px]`}
            >
              {lines.map((line, index) => {
                const markdownLine = md.render(line);
                return (
                  <div
                    key={index}
                    className={`${
                      lines.length === index + 1 ? "" : ""
                    } md:mt-[5px]`}
                    style={{ color: appearance?.textColor || "#FCFCFC" }}
                    dangerouslySetInnerHTML={{ __html: markdownLine }}
                  />
                );
              })}
            </div>
          </div>

          <div
            onClick={() => {
              (isReSpeaking || isSpeaking) && isHovered
                ? handleStopFetchingSpeech()
                : handleFetchSpeech(message);
            }}
            className={`w-[30px] shrink-0 ${
              !(isReSpeaking || isSpeaking) && "cursor-pointer"
            }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {message.typing === true ? (
              <div className="container">
                <div className="dot" />
              </div>
            ) : isReSpeaking || isSpeaking ? (
              isHovered ? (
                <STOP_ICON className="md:w-[30px] md:h-[30px] w-[25px] h-[25px]" />
              ) : (
                <img
                  src={audioWaveGif}
                  alt="wave"
                  className="md:w-[30px] md:h-[30px] w-[25px] h-[25px]"
                />
              )
            ) : (
              <REPEAT className="md:w-[30px] md:h-[30px] w-[25px] h-[25px]" />
            )}
          </div>
          {!message.typing && (
            <Tooltip
              title="Copied"
              open={showTooltip}
              disableHoverListener
              disableFocusListener
              disableTouchListener
            >
              <div
                className="ml-[5px] cursor-pointer"
                onClick={copyToClipboard}
              >
                <COPY className="md:w-[25px] md:h-[25px] w-[20px] h-[20px]" />
              </div>
            </Tooltip>
          )}
        </div>
      ) : (
        <div className="flex justify-end">
          <div className="flex">
            <p
              style={{
                background:
                  appearance?.assistantChatBackgroundColor || "#26A69A",
                color: appearance?.textColor || "#FCFCFC",
              }}
              className={`flex items-center px-[10px] py-[5px] text-[14px] leading-[16px] font-poppins font-[400] md:text-[18px] md:leading-[20px] rounded-l-1x rounded-br-1x shadow-user ml-[40px] md:ml-[50px] ${
                message.typing ? "typing-indicator" : ""
              }}`}
            >
              {message.content}
            </p>
            <div
              style={{
                borderWidth: "2px",
                borderColor:
                  appearance?.assistantChatBackgroundColor || "#26A69A",
                borderStyle: "solid",
              }}
              className="flex shrink-0 justify-center items-center shadow-xl p-[5px] w-[30px] h-[30px] md:w-[42px] md:h-[42px] rounded-[25px] ml-[10px]"
            >
              <USER_ICON
                style={{
                  fill: appearance?.assistantChatBackgroundColor || "#26A69A",
                }}
                className="h-full w-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatMessage;
